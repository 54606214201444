import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo.js"

import * as style from "../../styles/blogs.module.scss"

const BlogPage = ({ data }) => {
  console.log(data)

  return (
    <Layout>
      <Seo title="Blog" lang="hu" />
      <div className={style.container}>
        <h1>Írásaim</h1>
        {data.allMdx.nodes.map(node => (
          <figure key={node.frontmatter.image.childImageSharp.id}>
            <Link className={style.link} to={`/gabi-fitnesz-blog/${node.slug}`}>
              <GatsbyImage
                className={style.image}
                image={node.frontmatter.image.childImageSharp.gatsbyImageData}
                alt={node.frontmatter.image_alt}
              />
              <figcaption>{node.frontmatter.title}</figcaption>
            </Link>
          </figure>
        ))}
      </div>
    </Layout>
  )
}

export const data = graphql`
  query {
    allMdx(
      sort: { fields: frontmatter___order, order: ASC }
      filter: { fileAbsolutePath: { regex: "/(blog)/" } }
    ) {
      nodes {
        frontmatter {
          title
          image_alt
          order
          image {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
        slug
      }
    }
  }
`
export default BlogPage
